import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { lemonsoftRequest } from '../../authConfig'
import messages from './messages'

import Button from 'components/Button'

import { checkLogin } from 'containers/App/actions'
import './styles.css'
import token from 'api/TokenStorage'
import { useNavigate } from 'react-router'
import { lemonMsal } from 'containers/App/sagas'

function LemonLogin({ intl: { formatMessage } }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleLogin = async () => {
    const activeRedirect = await lemonMsal.handleRedirectPromise()
    if (activeRedirect && activeRedirect.accessToken) {
      token.setTokens(activeRedirect.accessToken, activeRedirect.accessToken)
      dispatch(checkLogin({ navigate }))
      return
    }

    const activeLemonAccounts = lemonMsal
      .getAllAccounts()
      .filter((acc) => acc.environment === 'lemonsoftb2c.b2clogin.com')

    if (activeLemonAccounts.length > 0) {
      try {
        const accessTokenRequest = {
          ...lemonsoftRequest,
          account: activeLemonAccounts?.[0],
        }
        const tokenResponse = await lemonMsal.acquireTokenSilent(
          accessTokenRequest
        )
        console.log('Acquire entra token silent success', accessTokenRequest)
        const accessToken = tokenResponse.accessToken
        token.setTokens(accessToken, accessToken)
        dispatch(checkLogin({ navigate }))
        return
      } catch (err) {
        console.log('Acquire entra token silent failed', err)
      }
    }

    localStorage.setItem('redirectType', 'lemon')
    lemonMsal.loginRedirect(lemonsoftRequest)
  }

  useEffect(() => {
    if (searchParams.get('login_hint') === 'lemonsoft') {
      searchParams.delete('login_hint')
      setSearchParams(searchParams)
      handleLogin()
    }
  })

  return (
    <Button onClick={handleLogin} className="lemonsoftLogin">
      {formatMessage(messages.useLemonLogin)}
    </Button>
  )
}
export default injectIntl(LemonLogin)
