/*
 * Users Messages
 *
 * This contains all the text for the Users component.
 */
import { defineMessages } from 'react-intl'
import {
  COMPANY_USER,
  COMPANY_SUPER_USER,
  CUSTOMER_USER,
  CUSTOMER_SUPER_USER,
  SYSTEM_USER,
  SYSTEM_SUPER_USER,
  REPORT_READER,
} from 'containers/Role/constants'

export default defineMessages({
  assignRolesToUser: {
    id: 'app.containers.Users.assignRoles',
    defaultMessage: 'Assign roles to {userName}',
  },
  assignRoles: {
    id: 'app.containers.Users.assignRoles',
    defaultMessage: 'Assign roles',
  },
  addRoles: {
    id: 'app.containers.Users.addRoles',
    defaultMessage: 'Add roles',
  },
  customerRoles: {
    id: 'app.containers.Users.customerRoles',
    defaultMessage: 'Customer roles',
  },
  aadAccountExistsText: {
    id: 'app.containers.Users.aadAccountExistsText',
    defaultMessage:
      'This username is now converted but we did not send any redundant emails this time. User may sign in using the email address you just gave.',
  },
  aadAccountExistsTitle: {
    id: 'app.containers.Users.aadAccountExistsTitle',
    defaultMessage: 'Account already exists',
  },
  close: {
    id: 'app.containers.Users.close',
    defaultMessage: 'Close',
  },
  createNewRole: {
    id: 'app.containers.Users.createNewRole',
    defaultMessage: 'Create new role',
  },
  newIntegrationUser: {
    id: 'app.containers.Users.newIntegrationUser',
    defaultMessage: 'New Integration User',
  },
  emailRequired: {
    id: 'app.containers.Users.emailRequired',
    defaultMessage: 'Email is required',
  },
  emailValidationErrorMessage: {
    id: 'app.containers.Users.emailValidationErrorMessage',
    defaultMessage: 'Email is not valid email',
  },
  headerAllUsers: {
    id: 'app.containers.Users.headerUsers',
    defaultMessage: 'All Users',
  },
  headerUsers: {
    id: 'app.containers.Users.headerUsers',
    defaultMessage: 'Users',
  },
  required: {
    id: 'app.containers.Users.required',
    defaultMessage: 'At least one role is required',
  },
  headerCustomerUsers: {
    id: 'app.containers.Users.headerCustomerUsers',
    defaultMessage: 'Customer Users',
  },
  headerAdmins: {
    id: 'app.containers.Users.headerAdmins',
    defaultMessage: 'Admins',
  },
  invite: {
    id: 'app.containers.Users.invite',
    defaultMessage: 'Invite',
  },
  newUser: {
    id: 'app.containers.Users.createUser',
    defaultMessage: 'New User',
    description: 'new user button',
  },
  authentication: {
    id: 'app.containers.Users.Authentication',
    defaultMessage: 'Authentication method',
    description: 'Table column header',
  },
  deleteModalMessage: {
    id: 'app.containers.Users.defaultMessage',
    defaultMessage:
      'Delete will remove user from Azure and convert all profiles to Finazilla accounts.',
    description: 'Delete Modal Text',
  },
  deleteModalMessageWithReport: {
    id: 'app.containers.Users.defaultMessageWithReport',
    defaultMessage:
      'Are you sure you want to delete this user? All reports made by this user will be deleted as well.',
    description: 'Delete Modal Text with Report',
  },
  tableName: {
    id: 'app.containers.Users.tableName',
    defaultMessage: 'Name',
    description: 'Table column header',
  },
  tableEmail: {
    id: 'app.containers.Users.tableEmail',
    defaultMessage: 'Email',
    description: 'Table column header',
  },
  tableUsername: {
    id: 'app.containers.Users.tableUsername',
    defaultMessage: 'Username',
    description: 'Table column header',
  },
  tableDefaultCompany: {
    id: 'app.containers.Users.tableDefaultCompany',
    defaultMessage: 'Default company',
  },
  tableDefaultPage: {
    id: 'app.containers.Users.tableDefaultPage',
    defaultMessage: 'Default page',
  },
  tableValidFrom: {
    id: 'app.containers.Users.tableValidFrom',
    defaultMessage: 'Valid from',
  },
  tableValidTo: {
    id: 'app.containers.Users.tableValidTo',
    defaultMessage: 'Valid to',
  },
  tableActions: {
    id: 'app.containers.Users.tableActions',
    defaultMessage: 'Actions',
  },
  unlink: {
    id: 'app.containers.Users.unlink',
    defaultMessage: 'Unlink',
  },
  adAccount: {
    id: 'app.containers.Users.unlinkAll',
    defaultMessage: 'Entra ID Account',
  },
  delete: {
    id: 'app.containers.Users.delete',
    defaultMessage: 'Delete',
    description: 'Delete button',
  },
  [COMPANY_USER]: {
    id: 'app.components.UserRoles.companyUser',
    defaultMessage: 'Default Member',
  },
  [COMPANY_SUPER_USER]: {
    id: 'app.components.UserRoles.companySuperUser',
    defaultMessage: 'Company Admin',
  },
  [CUSTOMER_SUPER_USER]: {
    id: 'app.components.UserRoles.customerSuperUser',
    defaultMessage: 'Customer Admin',
  },
  [CUSTOMER_USER]: {
    id: 'app.components.UserRoles.customerUser',
    defaultMessage: 'Default Customer Member',
  },
  [SYSTEM_SUPER_USER]: {
    id: 'app.components.UserRoles.systemSuperUser',
    defaultMessage: 'System Super User',
  },
  [SYSTEM_USER]: {
    id: 'app.components.UserRoles.systemUser',
    defaultMessage: 'System User',
  },
  [REPORT_READER]: {
    id: 'app.components.UserRoles.reportReader',
    defaultMessage: 'Report Reader',
  },
})
