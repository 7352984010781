// Import all the third party stuff
import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'

// Set up the router, wrapping all Routes in the App component
import App from 'containers/App'
import AllUsers from 'containers/Users/AllUsers'

const ReportSelectionsBeta = React.lazy(() =>
  import('containers/ReportSelectionsBeta')
)
const Dashboards = React.lazy(() => import('containers/Dashboards'))
const Reports = React.lazy(() => import('containers/Reports'))
const CustomerReportSelections = React.lazy(() =>
  import('containers/CustomerReportSelections')
)

const ReportSelections = React.lazy(() => import('containers/ReportSelections'))
const ReportSelection = React.lazy(() => import('containers/ReportSelections'))
const Report = React.lazy(() => import('containers/Report'))
const SystemDashboards = React.lazy(() => import('containers/SystemDashboards'))
const AccountSchemeTemplates = React.lazy(() =>
  import('containers/AccountSchemeTemplates')
)
const AccountSchemeTemplate = React.lazy(() =>
  import('containers/AccountSchemeTemplate')
)
const SystemReportSelections = React.lazy(() =>
  import('containers/SystemReportSelections')
)
const AccountSchemes = React.lazy(() => import('containers/AccountSchemes'))
const AccountScheme = React.lazy(() => import('containers/AccountScheme'))
const ReportSchemeTemplates = React.lazy(() =>
  import('containers/ReportSchemes')
)
const ReportScheme = React.lazy(() => import('containers/ReportScheme'))
const ReportSchemes = React.lazy(() => import('containers/ReportSchemes'))

const CalculationConstants = React.lazy(() =>
  import('containers/CalculationConstants')
)
const CalculationConstant = React.lazy(() =>
  import('containers/CalculationConstant')
)
const Customers = React.lazy(() => import('containers/Customers'))
const Customer = React.lazy(() => import('containers/Customer'))
const IntegrationSourceSystems = React.lazy(() =>
  import('containers/IntegrationSourceSystems')
)
const Users = React.lazy(() => import('containers/Users'))
const User = React.lazy(() => import('containers/User'))
const CustomerDashboards = React.lazy(() =>
  import('containers/CustomerDashboards')
)

const FasFormSelection = React.lazy(() => import('containers/FasFormSelection'))

const Companies = React.lazy(() => import('containers/Companies'))
const Company = React.lazy(() => import('containers/Company'))

const CompanyIndex = React.lazy(() => import('containers/CompanyIndex'))
const AccountingPeriods = React.lazy(() =>
  import('containers/AccountingPeriods')
)
const Accounts = React.lazy(() => import('containers/Accounts'))

const Budgets = React.lazy(() => import('containers/Budgets'))
const Budget = React.lazy(() => import('containers/Budget'))

const BudgetMonitor = React.lazy(() => import('containers/BudgetMonitor'))

const Dimensions = React.lazy(() => import('containers/Dimensions'))
const Dimension = React.lazy(() => import('containers/Dimension'))
const IntegrationLogs = React.lazy(() => import('containers/IntegrationLogs'))
const ReportingGroups = React.lazy(() => import('containers/ReportingGroups'))
const RollingRules = React.lazy(() => import('containers/RollingRules'))
const RollingRule = React.lazy(() => import('containers/RollingRule'))
const Roles = React.lazy(() => import('containers/Roles'))
const Role = React.lazy(() => import('containers/Role'))
const Voucher = React.lazy(() => import('containers/Voucher'))
const Vouchers = React.lazy(() => import('containers/Vouchers'))
const CashFlowContainer = React.lazy(() =>
  import('containers/CashFlowContainer')
)
const NotFoundPage = React.lazy(() => import('containers/NotFoundPage'))

const QuickBooksCallbackModel = React.lazy(() =>
  import('components/QuickBooksCallbackModel')
)
const XeroCallbackModel = React.lazy(() =>
  import('components/XeroCallbackModel')
)

const FinazillaRouter = ({ translatedMessages }) => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App messages={translatedMessages} />}>
        <Route index element={<Dashboards />} />
        <Route path="dashboards" element={<Dashboards />} />
        <Route path="dashboards/:dashboardId" element={<Dashboards />} />
        <Route
          path="callbacks/quickbooks"
          element={<QuickBooksCallbackModel />}
        />
        <Route path="callbacks/xero" element={<XeroCallbackModel />} />
        <Route
          path="systemreportselections"
          element={<SystemReportSelections />}
        >
          <Route path="edit/:reportId" element={<ReportSelection />} />
        </Route>
        <Route
          path="systemreportselections-beta"
          element={<SystemReportSelections />}
        >
          <Route path="edit/:reportId" element={<ReportSelectionsBeta />} />
        </Route>

        <Route path="/reports" element={<Reports />}>
          <Route path="edit/:reportId" element={<ReportSelections />} />
          <Route path=":reportId" element={<Report />} />
        </Route>
        <Route path="/reports-beta" element={<Reports />}>
          <Route path="edit/:reportId" element={<ReportSelectionsBeta />} />
        </Route>

        <Route path="/systemdashboards" element={<SystemDashboards />}>
          <Route path=":dashboardId" element={<SystemDashboards />} />
        </Route>

        <Route
          path="/accountschemetemplates"
          element={<AccountSchemeTemplates />}
        >
          <Route
            path=":accountSchemeTemplateId"
            element={<AccountSchemeTemplate />}
          />
        </Route>

        <Route
          path="/reportschemetemplates"
          element={<ReportSchemeTemplates />}
        >
          <Route path=":reportSchemeId" element={<ReportScheme />} />
        </Route>

        <Route path="/reportschemes" element={<ReportSchemes />}>
          <Route path=":reportSchemeId" element={<ReportScheme />} />
        </Route>

        <Route path="/calculationconstants" element={<CalculationConstants />}>
          <Route
            path=":calculationConstantId"
            element={<CalculationConstant />}
          />
        </Route>

        <Route
          path="/integrationsourcesystems"
          element={<IntegrationSourceSystems />}
        />

        <Route path={'/users'} element={<Users />}>
          <Route path=":userId" element={<User />} />
        </Route>
        <Route path="/allusers" element={<AllUsers />}></Route>

        <Route path="/customers" element={<Customers />}>
          <Route path=":customerCode" element={<Customer />} />
        </Route>

        <Route path=":customerCode">
          <Route index element={<Companies />} />
          <Route path="companies/:companyCode" element={<Company />} />

          <Route path="reports" element={<Reports />}>
            <Route path="edit/:reportId" element={<ReportSelections />} />
            <Route path=":reportId" element={<Report />} />
          </Route>
          <Route path="reports-beta" element={<Reports />}>
            <Route path="edit/:reportId" element={<ReportSelectionsBeta />} />
          </Route>
          <Route path="dashboards" element={<Dashboards />}>
            <Route path=":dashboardId" element={<Dashboards />} />
          </Route>

          <Route path="customerdashboards" element={<CustomerDashboards />}>
            <Route path=":dashboardId" element={<CustomerDashboards />} />
          </Route>

          <Route
            path="accountSchemeTemplates"
            element={<AccountSchemeTemplates />}
          >
            <Route
              path=":accountSchemeTemplateId"
              element={<AccountSchemeTemplate />}
            />
          </Route>

          <Route path="reportschemes" element={<ReportSchemes />}>
            <Route path=":reportSchemeId" element={<ReportScheme />} />
          </Route>

          <Route
            path="reportschemetemplates"
            element={<ReportSchemeTemplates />}
          >
            <Route path=":reportSchemeId" element={<ReportScheme />} />
          </Route>
          <Route path="users" element={<Users />}>
            <Route path=":userId" element={<User />} />
          </Route>
          <Route path="calculationconstants" element={<CalculationConstants />}>
            <Route
              path=":calculationConstantId"
              element={<CalculationConstant />}
            />
          </Route>

          <Route path="customerreports" element={<CustomerReportSelections />}>
            <Route path="edit/:reportId" element={<ReportSelection />} />
            <Route path=":reportId" element={<Report />} />
          </Route>
          <Route
            path="customerreports-beta"
            element={<CustomerReportSelections />}
          >
            <Route path="edit/:reportId" element={<ReportSelectionsBeta />} />
          </Route>

          <Route path=":companyCode" element={<CompanyIndex />}>
            <Route index element={<Dashboards />} />

            <Route path="fas" element={<FasFormSelection />}></Route>

            <Route
              path="fas/edit/:generatorId"
              element={<FasFormSelection />}
            />

            <Route path="accountingperiods" element={<AccountingPeriods />} />

            <Route path="reports" element={<Reports />}>
              <Route path="edit/:reportId" element={<ReportSelections />} />
              <Route path=":reportId" element={<Report />} />
            </Route>

            <Route path="reports-beta" element={<Reports />}>
              <Route path="edit/:reportId" element={<ReportSelectionsBeta />} />
            </Route>

            <Route path="dashboards" element={<Dashboards />}>
              <Route path=":dashboardId" element={<Dashboards />} />
            </Route>

            <Route path="accounts" element={<Accounts />} />

            <Route path="accountschemes" element={<AccountSchemes />}>
              <Route path=":accountSchemeId" element={<AccountScheme />} />
            </Route>

            <Route
              path="accountschemetemplates"
              element={<AccountSchemeTemplates />}
            >
              <Route
                path=":accountSchemeTemplateId"
                element={<AccountSchemeTemplate />}
              />
            </Route>

            <Route
              path="calculationconstants"
              element={<CalculationConstants />}
            >
              <Route
                path=":calculationConstantId"
                element={<CalculationConstant />}
              />
            </Route>

            <Route path="budgets" element={<Budgets />}>
              <Route path=":budgetId" element={<Budget />}>
                <Route path="monitor" element={<BudgetMonitor />} />
                <Route
                  path="calculationconstants"
                  element={<CalculationConstants />}
                >
                  <Route
                    path=":calculationConstantId"
                    element={<CalculationConstant />}
                  />
                </Route>
              </Route>
            </Route>

            <Route path="dimensions" element={<Dimensions />}>
              <Route path=":dimensionId" element={<Dimension />}></Route>
            </Route>

            <Route path="integrationlogs" element={<IntegrationLogs />} />

            <Route path="reportinggroups" element={<ReportingGroups />} />

            <Route path="reportschemes" element={<ReportSchemes />}>
              <Route path=":reportSchemeId" element={<ReportScheme />} />
            </Route>

            <Route path="rollingrules" element={<RollingRules />}>
              <Route path=":rollingRuleId" element={<RollingRule />} />
            </Route>

            <Route path="roles" element={<Roles />}>
              <Route path=":roleId" element={<Role />} />
            </Route>

            <Route path="users" element={<Users />}>
              <Route path=":userId" element={<User />} />
            </Route>

            <Route path="vouchers" element={<Vouchers />}>
              <Route path=":voucherId" element={<Voucher />} />
            </Route>

            <Route path="cashflow" element={<CashFlowContainer />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
)

export default FinazillaRouter
