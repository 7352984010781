/*
 *
 * User constants
 *
 */

export const UPDATE_USER = 'app/User/UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'app/User/UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'app/User/UPDATE_USER_ERROR'

export const CHANGE_PASSWORD = 'app/User/CHANGE_PASSWORD'
export const RESET_PASSWORD = 'app/User/RESET_PASSWORD'
export const CHANGE_PASSWORD_ERROR = 'app/User/CHANGE_PASSWORD_ERROR'
export const CHANGE_PASSWORD_SUCCESS = 'app/User/CHANGE_PASSWORD_SUCCESS'

export const GET_USER_ROLES = 'app/User/GET_USER_ROLES'
export const GET_USER_ROLES_ERROR = 'app/User/GET_USER_ROLES_ERROR'
export const GET_USER_ROLES_SUCCESS = 'app/User/GET_USER_ROLES_SUCCESS'

export const SET_USER_ROLES = 'app/Users/SET_USER_ROLES'
export const SET_USER_ROLES_SUCCESS = 'app/Users/SET_USER_ROLES_SUCCESS'
export const SET_USER_ROLES_ERROR = 'app/Users/SET_USER_ROLES_ERROR'

export const CREATE_USER_ROLE = 'app/User/CREATE_USER_ROLE'
export const CREATE_USER_ROLE_ERROR = 'app/User/CREATE_USER_ROLE_ERROR'
export const CREATE_USER_ROLE_SUCCESS = 'app/User/CREATE_USER_ROLE_SUCCESS'

export const DELETE_USER_ROLE = 'app/User/DELETE_USER_ROLE'
export const DELETE_USER_ROLE_ERROR = 'app/User/DELETE_USER_ROLE_ERROR'
export const DELETE_USER_ROLE_SUCCESS = 'app/User/DELETE_USER_ROLE_SUCCESS'

export const SET_VALIDITY_RANGE_ENABLED = 'app/User/SET_VALIDITY_RANGE_ENABLED'

export const CLEAR_API_KEY = 'app/User/CLEAR_API_KEY'
export const SET_API_KEY = 'app/User/SET_API_KEY'
export const RENEW_API_KEY = 'app/User/RENEW_API_KEY'
