import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { handleError } from 'api/api-utils'
import * as Diff from 'immutablediff'
import { Record } from 'immutable'

import userApi from 'api/UsersApi'
import userRoleApi from 'api/UserRolesApi'

import {
  CHANGE_PASSWORD,
  CREATE_USER_ROLE,
  DELETE_USER_ROLE,
  GET_USER_ROLES,
  UPDATE_USER,
  RESET_PASSWORD,
  RENEW_API_KEY,
  SET_USER_ROLES,
} from './constants'
import {
  changePasswordError,
  changePasswordSuccess,
  createUserRoleError,
  createUserRoleSuccess,
  deleteUserRoleError,
  deleteUserRoleSuccess,
  getUserRolesError,
  getUserRolesSuccess,
  updateUserSuccess,
  updateUserError,
  setApiKey,
  setUserRolesSuccess,
  setUserRolesError,
} from './actions'

const UpdateUserRecord = Record({
  name: undefined,
  email: undefined,
  validFrom: undefined,
  validTo: undefined,
  defaultCompanyId: undefined,
  defaultPage: undefined,
  theme: undefined,
  finvoicerEmail: undefined,
})

export function* updateUser(action) {
  const {
    user: { id: userId },
    user,
    updatedUser,
  } = action

  const patch = Diff(
    new UpdateUserRecord(user),
    new UpdateUserRecord(updatedUser)
  ).toJS()
  try {
    const patchedUser = yield call(userApi.patchUser, { userId, patch })
    yield put(updateUserSuccess(patchedUser))
  } catch (error) {
    yield put(handleError(error, updateUserError))
  }
}

export function* changePassword(action) {
  const {
    user: { id: userId, email },
    oldPassword: currentPassword,
    newPassword,
  } = action
  try {
    yield call(userApi.updatePassword, {
      userId,
      email,
      currentPassword,
      newPassword,
    })
    yield put(changePasswordSuccess())
  } catch (error) {
    yield put(handleError(error, changePasswordError))
  }
}

export function* resetPassword(action) {
  const {
    user: { id: userId, email },
    newPassword,
  } = action
  try {
    yield call(userApi.resetPassword, {
      userId,
      email,
      newPassword,
    })
    yield put(changePasswordSuccess())
  } catch (error) {
    yield put(handleError(error, changePasswordError))
  }
}

export function* getUserRoles(action) {
  const { userId, customerCode, companyCode } = action
  try {
    const userRoles = yield call(userRoleApi.getUserRoles, {
      userId,
      customerCode,
      companyCode,
    })
    yield put(getUserRolesSuccess({ userRoles, userId }))
  } catch (error) {
    yield put(handleError(error, getUserRolesError))
  }
}

export function* createUserRole(action) {
  const {
    user: { id },
    userRole: { id: roleId, customerCode, companyCode },
  } = action
  try {
    const userRole = yield call(userRoleApi.createUserRole, {
      customerCode,
      companyCode,
      userId: id,
      role: roleId,
    })
    yield put(createUserRoleSuccess(userRole))
  } catch (error) {
    yield put(handleError(error, createUserRoleError))
  }
}

export function* deleteUserRole(action) {
  const {
    user: { id },
    userRole: { id: roleId, customerCode, companyCode },
  } = action
  try {
    const userRole = yield call(userRoleApi.deleteUserRole, {
      customerCode,
      companyCode,
      userId: id,
      role: roleId,
    })
    yield put(deleteUserRoleSuccess(userRole))
  } catch (error) {
    yield put(handleError(error, deleteUserRoleError))
  }
}

export function* renewApiKey(action) {
  const { userId } = action
  try {
    const apiKey = yield call(userApi.renewApiKey, { userId })
    yield put(setApiKey(apiKey))
  } catch (error) {
    yield put(handleError(error, updateUserError))
  }
}

export function* setUserRoles(action) {
  const { companyCode, customerCode, roles, userId, view } = action
  try {
    const result = yield call(userRoleApi.setUserRoles, {
      companyCode,
      customerCode,
      roles,
      userId,
    })
    yield put(setUserRolesSuccess(view, result))
  } catch (error) {
    yield put(handleError(error, setUserRolesError, { view }))
  }
}

export function* userSaga() {
  yield all([
    takeEvery(UPDATE_USER, updateUser),
    takeEvery(CHANGE_PASSWORD, changePassword),
    takeEvery(RESET_PASSWORD, resetPassword),
    takeLatest(GET_USER_ROLES, getUserRoles),
    takeEvery(DELETE_USER_ROLE, deleteUserRole),
    takeEvery(CREATE_USER_ROLE, createUserRole),
    takeEvery(RENEW_API_KEY, renewApiKey),
    takeEvery(SET_USER_ROLES, setUserRoles),
  ])
}

// All sagas to be loaded
export default userSaga
