/* eslint-disable react/no-unknown-property */
/**
 *
 * FormField
 *
 * Wrapper Component for Form.Field and Bootstrap Form Styles
 *
 * Usage for before & after:
 * const afterField = <InputGroup.Button><Button onClick={this.doSomething}>Click meh!</Button></InputGroup.Button>;
 * <FormField name="somename" type="text" label="Might Label" after={afterField} />
 *
 * const atSign = <InputGroup.Addon>@</InputGroup.Addon>;
 * <FormField name="somename" type="text" label="I haz @-sign" before={atSign} />
 *
 * Documentation
 * http://jquense.github.io/react-formal/api/Field
 *
 * React Formal - https://jquense.github.io/react-formal/
 * React Widgets - http://jquense.github.io/react-widgets/
 * Yup - https://github.com/jquense/yup
 */

import PropTypes from 'prop-types'

import React from 'react'
import Form, { useField } from 'react-formal'
import { InputGroup, FormLabel, OverlayTrigger, Popover } from 'react-bootstrap'
import Select from 'react-select'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import classNames from 'classnames'
import {
  NumberPicker,
  DatePicker,
  DropdownList,
  Combobox,
  Multiselect,
} from 'react-widgets'

import styles from './styles.module.scss'
import { injectIntl } from 'react-intl'
import Icon from 'components/Icon'
import SelectList from './components/SelecList'
import { getCustomStyles } from './functions'

momentLocalizer(moment)

const dateFormats = [
  'L',
  'l',
  'DDMMYYYY',
  'DDMMYY',
  'DD.MM.YYYY',
  'DD.MM.YY',
  'DMYYYY',
  'DMYY',
  'D.M.YYYY',
  'D.M.YY',
]

const getFormWrapperClass = (type) => {
  switch (type) {
    case 'selectlist':
      return 'radio-container'
    default:
      return 'form-group'
  }
}

const getFormFieldWrapperClass = (type) => {
  switch (type) {
    case 'combobox':
    case 'multiselect':
    case 'datepicker':
    case 'dropdownlist':
    case 'number':
    case 'numberpicker':
    case 'react-select':
      return styles.formGeneric
    case 'boolean':
      return `form-check ${styles.booleanCheckBox}`
    case 'selectlist':
      return ''
    default:
      return 'form-control'
  }
}

const getFormFieldTypeWrapper = (props) => {
  const { type, name, className, children, id, ...rest } = props
  switch (type) {
    case 'combobox':
      return (
        <Form.Field name={name} {...rest} className={className}>
          {(props) => (
            <Combobox type={type} inputProps={{ id }} {...rest} {...props}>
              {children}
            </Combobox>
          )}
        </Form.Field>
      )
    case 'multiselect':
      return (
        <Form.Field name={name} {...rest} className={className}>
          {(props) => (
            <Multiselect type={type} inputProps={{ id }} {...rest} {...props}>
              {children}
            </Multiselect>
          )}
        </Form.Field>
      )
    case 'datepicker':
      return (
        <Form.Field name={name} {...rest} className={className}>
          {(props) => (
            <DatePicker
              type={type}
              parse={dateFormats}
              inputProps={{ id, 'aria-label': 'Datepicker: ' + name }}
              {...props}
              {...rest}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.target.blur()
                }
              }}
            >
              {children}
            </DatePicker>
          )}
        </Form.Field>
      )
    case 'dropdownlist':
      return (
        <Form.Field name={name} {...rest} className={className}>
          {(props) => (
            <DropdownList
              type={type}
              listProps={{ inputProps: { id } }}
              {...rest}
              {...props}
            >
              {children}
            </DropdownList>
          )}
        </Form.Field>
      )
    case 'checkbox':
    case 'radio':
    case 'selectlist':
      return <SelectList type={type} {...props} />
    case 'file':
      return (
        <Form.Field name={name} {...rest} className={className}>
          {(props, meta) => (
            <>
              <label
                {...rest}
                htmlFor={`file-field-${name}`}
                style={{
                  cursor: 'pointer',
                }}
              >
                {children}
              </label>
              <input
                id={`file-field-${name}`}
                className={children ? styles.displayNone : ''}
                type={'file'}
                onChange={(e) => meta.onChange(e.target.files[0])}
              />
            </>
          )}
        </Form.Field>
      )
    case 'number':
    case 'numberpicker':
      return (
        <Form.Field name={name} {...rest}>
          {(props) => (
            <NumberPicker
              type={type}
              className={className}
              inputProps={{ id }}
              {...rest}
              {...props}
            >
              {children}
            </NumberPicker>
          )}
        </Form.Field>
      )
    case 'select': //obsolete
      return (
        <Form.Field name={name} className={className} as={type} {...rest}>
          {(props, meta) => (
            <select
              {...rest}
              {...props}
              onChange={({ target }) => {
                if (rest?.onChange) rest?.onChange(target.value)
                meta.onChange(target.value)
              }}
            >
              {children}
            </select>
          )}
        </Form.Field>
      )
    case 'react-select':
      return (
        <Form.Field
          name={name}
          className={className}
          as={Select}
          styles={getCustomStyles}
          {...rest}
        />
      )
    default:
      return (
        <Form.Field
          name={name}
          className={className}
          type={type}
          id={id}
          {...rest}
        >
          {children}
        </Form.Field>
      )
  }
}

const FormField = ({
  children,
  label,
  labelAfter,
  name,
  noMessages,
  type,
  before,
  after,
  formGroupClassName,
  formFieldHelpMessageClassName,
  inputGroupClassName,
  containerClassName,
  className,
  itemMessages,
  hint,
  hintDirection = 'right',
  error,
  intl: { formatMessage },
  ...props
}) => {
  const [, meta] = useField(name)
  const inputType = type ? type : meta?.resolvedType
  const fieldClass =
    before || after || inputGroupClassName
      ? classNames(getFormFieldWrapperClass(inputType), formGroupClassName)
      : getFormFieldWrapperClass(inputType)
  const FormFieldComponent = getFormFieldTypeWrapper({
    type: inputType,
    name,
    className: classNames(className, fieldClass, styles.formField),
    children,
    id: name,
    ...props,
  })
  return (
    /* eslint-disable jsx-a11y/label-has-for */
    <div
      className={classNames(
        getFormWrapperClass(inputType),
        formGroupClassName,
        containerClassName
      )}
    >
      {!labelAfter && label && (
        <FormLabel htmlFor={name}>
          {label}
          {hint && (
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement={hintDirection}
              overlay={<Popover id={hint}>{hint}</Popover>}
            >
              <span>
                <Icon name="info-sign" className={styles.infoIcon} />
              </span>
            </OverlayTrigger>
          )}
        </FormLabel>
      )}
      {before || after || inputGroupClassName ? (
        <InputGroup className={classNames(inputGroupClassName)}>
          {before}
          {FormFieldComponent}
          {after}
        </InputGroup>
      ) : (
        <>{FormFieldComponent}</>
      )}
      {!noMessages && (
        <Form.Message
          className={classNames(
            styles.validationError,
            formFieldHelpMessageClassName
          )}
          for={name}
        />
      )}
      {error && (
        <div
          className={classNames(
            styles.validationError,
            formFieldHelpMessageClassName
          )}
        >
          {error}
        </div>
      )}
      {labelAfter && label && <FormLabel htmlFor={name}>{label}</FormLabel>}
    </div>
  )
}
/* eslint-enable jsx-a11y/label-has-for */

FormField.propTypes = {
  after: PropTypes.any,
  before: PropTypes.any,
  children: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  noMessages: PropTypes.bool,
  formGroupClassName: PropTypes.string,
  formFieldHelpMessageClassName: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  labelAfter: PropTypes.bool,
}

export default injectIntl(FormField)
