/*
 *
 * User actions
 *
 */

import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CREATE_USER_ROLE,
  CREATE_USER_ROLE_ERROR,
  CREATE_USER_ROLE_SUCCESS,
  GET_USER_ROLES,
  GET_USER_ROLES_ERROR,
  GET_USER_ROLES_SUCCESS,
  DELETE_USER_ROLE,
  DELETE_USER_ROLE_ERROR,
  DELETE_USER_ROLE_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  SET_VALIDITY_RANGE_ENABLED,
  RESET_PASSWORD,
  CLEAR_API_KEY,
  SET_API_KEY,
  RENEW_API_KEY,
  SET_USER_ROLES,
  SET_USER_ROLES_ERROR,
  SET_USER_ROLES_SUCCESS,
} from './constants'

export const setApiKey = (apiKey) => ({
  type: SET_API_KEY,
  apiKey,
})

export const clearApiKey = () => ({
  type: CLEAR_API_KEY,
})

export const renewApiKey = (userId) => ({
  type: RENEW_API_KEY,
  userId,
})

export const updateUser = (user, updatedUser) => ({
  type: UPDATE_USER,
  user,
  updatedUser,
})

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  user,
})

export const updateUserError = (error) => ({
  type: UPDATE_USER_ERROR,
  error,
})

export const changePassword = (user, oldPassword, newPassword) => ({
  type: CHANGE_PASSWORD,
  user,
  oldPassword,
  newPassword,
})

export const resetPassword = (user, newPassword) => ({
  type: RESET_PASSWORD,
  user,
  newPassword,
})

export const changePasswordError = (error) => ({
  type: CHANGE_PASSWORD_ERROR,
  error,
})

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
})

export const setValidityRangeEnabled = (enabled) => ({
  type: SET_VALIDITY_RANGE_ENABLED,
  enabled,
})

export const setUserRoles = ({
  userId,
  roles,
  companyCode,
  customerCode,
  view,
}) => ({
  type: SET_USER_ROLES,
  userId,
  companyCode,
  customerCode,
  roles,
  view,
})

export const setUserRolesSuccess = (view, roles) => ({
  type: SET_USER_ROLES_SUCCESS,
  view,
  roles,
})

export const setUserRolesError = (error, view) => ({
  type: SET_USER_ROLES_ERROR,
  error,
})

export const getUserRoles = ({ userId, customerCode, companyCode }) => ({
  type: GET_USER_ROLES,
  userId,
  customerCode,
  companyCode,
})

export const getUserRolesError = () => ({
  type: GET_USER_ROLES_ERROR,
})

export const getUserRolesSuccess = ({ userRoles, userId }) => ({
  type: GET_USER_ROLES_SUCCESS,
  userRoles,
  userId,
})

export const createUserRole = (user, userRole) => ({
  type: CREATE_USER_ROLE,
  user,
  userRole,
})

export const createUserRoleError = (error) => ({
  type: CREATE_USER_ROLE_ERROR,
  error,
})

export const createUserRoleSuccess = (userRole) => ({
  type: CREATE_USER_ROLE_SUCCESS,
  userRole,
})

export const deleteUserRole = (user, userRole) => ({
  type: DELETE_USER_ROLE,
  user,
  userRole,
})

export const deleteUserRoleError = (error) => ({
  type: DELETE_USER_ROLE_ERROR,
  error,
})

export const deleteUserRoleSuccess = (userRole) => ({
  type: DELETE_USER_ROLE_SUCCESS,
  userRole,
})
